<template>
    <div class="content">
        <div class="row">
            
            <div class="col-md-12">
                <vuestic-widget :headerText="$t('view.typeproduct.title')">
                    <p>{{$t('view.typeproduct.description')}}</p>
                    <div class="row">
                        <div class="col-md-12 pull-right">
                            <button type="button" v-on:click="New_typeproduct" class="btn btn-primary btn-sm">{{$t('view.typeproduct.new')}}</button>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <TableTypeProduct></TableTypeProduct>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import TableTypeProduct from '../tables/TableTypeProduct/TableTypeProduct.vue'

    import {SpringSpinner} from 'epic-spinners'

    export default {
        name:       'table-type-product',
        components: {
            SpringSpinner,
            TableTypeProduct
        },
        data() {
            return {}
        },
        methods:    {
            New_typeproduct () {
                this.$router.replace('product/new')
            }

        }
    }
</script>

<style lang="scss">
    
    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }
</style>
